// CRC 校验表
var crc_table = new Array(
  0x00,
  0x31,
  0x62,
  0x53,
  0xc4,
  0xf5,
  0xa6,
  0x97,
  0xb9,
  0x88,
  0xdb,
  0xea,
  0x7d,
  0x4c,
  0x1f,
  0x2e,
  0x43,
  0x72,
  0x21,
  0x10,
  0x87,
  0xb6,
  0xe5,
  0xd4,
  0xfa,
  0xcb,
  0x98,
  0xa9,
  0x3e,
  0x0f,
  0x5c,
  0x6d,
  0x86,
  0xb7,
  0xe4,
  0xd5,
  0x42,
  0x73,
  0x20,
  0x11,
  0x3f,
  0x0e,
  0x5d,
  0x6c,
  0xfb,
  0xca,
  0x99,
  0xa8,
  0xc5,
  0xf4,
  0xa7,
  0x96,
  0x01,
  0x30,
  0x63,
  0x52,
  0x7c,
  0x4d,
  0x1e,
  0x2f,
  0xb8,
  0x89,
  0xda,
  0xeb,
  0x3d,
  0x0c,
  0x5f,
  0x6e,
  0xf9,
  0xc8,
  0x9b,
  0xaa,
  0x84,
  0xb5,
  0xe6,
  0xd7,
  0x40,
  0x71,
  0x22,
  0x13,
  0x7e,
  0x4f,
  0x1c,
  0x2d,
  0xba,
  0x8b,
  0xd8,
  0xe9,
  0xc7,
  0xf6,
  0xa5,
  0x94,
  0x03,
  0x32,
  0x61,
  0x50,
  0xbb,
  0x8a,
  0xd9,
  0xe8,
  0x7f,
  0x4e,
  0x1d,
  0x2c,
  0x02,
  0x33,
  0x60,
  0x51,
  0xc6,
  0xf7,
  0xa4,
  0x95,
  0xf8,
  0xc9,
  0x9a,
  0xab,
  0x3c,
  0x0d,
  0x5e,
  0x6f,
  0x41,
  0x70,
  0x23,
  0x12,
  0x85,
  0xb4,
  0xe7,
  0xd6,
  0x7a,
  0x4b,
  0x18,
  0x29,
  0xbe,
  0x8f,
  0xdc,
  0xed,
  0xc3,
  0xf2,
  0xa1,
  0x90,
  0x07,
  0x36,
  0x65,
  0x54,
  0x39,
  0x08,
  0x5b,
  0x6a,
  0xfd,
  0xcc,
  0x9f,
  0xae,
  0x80,
  0xb1,
  0xe2,
  0xd3,
  0x44,
  0x75,
  0x26,
  0x17,
  0xfc,
  0xcd,
  0x9e,
  0xaf,
  0x38,
  0x09,
  0x5a,
  0x6b,
  0x45,
  0x74,
  0x27,
  0x16,
  0x81,
  0xb0,
  0xe3,
  0xd2,
  0xbf,
  0x8e,
  0xdd,
  0xec,
  0x7b,
  0x4a,
  0x19,
  0x28,
  0x06,
  0x37,
  0x64,
  0x55,
  0xc2,
  0xf3,
  0xa0,
  0x91,
  0x47,
  0x76,
  0x25,
  0x14,
  0x83,
  0xb2,
  0xe1,
  0xd0,
  0xfe,
  0xcf,
  0x9c,
  0xad,
  0x3a,
  0x0b,
  0x58,
  0x69,
  0x04,
  0x35,
  0x66,
  0x57,
  0xc0,
  0xf1,
  0xa2,
  0x93,
  0xbd,
  0x8c,
  0xdf,
  0xee,
  0x79,
  0x48,
  0x1b,
  0x2a,
  0xc1,
  0xf0,
  0xa3,
  0x92,
  0x05,
  0x34,
  0x67,
  0x56,
  0x78,
  0x49,
  0x1a,
  0x2b,
  0xbc,
  0x8d,
  0xde,
  0xef,
  0x82,
  0xb3,
  0xe0,
  0xd1,
  0x46,
  0x77,
  0x24,
  0x15,
  0x3b,
  0x0a,
  0x59,
  0x68,
  0xff,
  0xce,
  0x9d,
  0xac
);
// A1
var A1_SERVO_STATUS = {
  motoroff: 0x00,
  manualcontrol: 0x01,
  followyaw: 0x03,
  homeposition: 0x04,
  trackmode: 0x06,
  turntorelativeangle: 0x09,
  lockyaw: 0x0a,
  turntoframeangle: 0x0b,
  rcmode: 0x0d,
  movetofinger: 0x0e,
  noaction: 0x0f,
  lookdown: 0x12,
  centeryaw: 0x16,
  levelpitch: 0x17
};
// crc校验字节生成函数
function cal_crc_table(array) {
  var crc = 0;
  var i = 1;
  var len = array[2];
  while (len--) {
    crc = crc_table[crc ^ array[i]];
    i++;
  }
  return crc;
}
var A1_PARAM_INT16 = {
  PARAM1: 0x0000,
  PARAM2: 0x0000,
  PARAM3: 0x0000,
  PARAM4: 0x0000
};
var A1_CMD_ANGLE = {
  server_status: A1_SERVO_STATUS.noaction,
  param1: A1_PARAM_INT16.PARAM1,
  param2: A1_PARAM_INT16.PARAM2,
  param3: A1_PARAM_INT16.PARAM3,
  param4: A1_PARAM_INT16.PARAM4
};
var viewlink_cmdLEN = {
  // cmd_DATA_len + 3(len+cmdid+cs)
  A1C1E1: 17,
  A1C1E1S1: 31,
  A2C2E2: 13,
  A2C2E2S2: 18,
  T1F1B1D1: 44,
  T2F2B2D2: 52,
  A1: 12,
  A2: 5,
  C1: 5,
  C2: 6,
  E1: 6,
  E2: 8,
  S1: 17,
  S2: 8,
  U: 5,
  V: 5,
  M_AHRS: 45,
  HEART_BEAT: 4,
  SHAKE_HAND: 4,
  // //FOLLOW CMD NOT +3
  T1F1B1D1_DATA: 41,
  T2F2B2D2_DATA: 49,
  T1_DATA: 22,
  F1_DATA: 1,
  B1_DATA: 6,
  D1_DATA: 12,
  T2_DATA: 18,
  F2_DATA: 15,
  B2_DATA: 11,
  D2_DATA: 5
};
var viewlinkheadlen = 3;
var viewlinkFrameLen = {
  // the position of checksum
  A1C1E1: viewlink_cmdLEN.A1C1E1 + viewlinkheadlen,
  A1C1E1S1: viewlink_cmdLEN.A1C1E1S1 + viewlinkheadlen,
  A2C2E2: viewlink_cmdLEN.A2C2E2 + viewlinkheadlen,
  A2C2E2S2: viewlink_cmdLEN.A2C2E2S2 + viewlinkheadlen,
  A1: viewlink_cmdLEN.A1 + viewlinkheadlen,
  A2: viewlink_cmdLEN.A2 + viewlinkheadlen,
  C1: viewlink_cmdLEN.C1 + viewlinkheadlen,
  C2: viewlink_cmdLEN.C2 + viewlinkheadlen,
  E1: viewlink_cmdLEN.E1 + viewlinkheadlen,
  E2: viewlink_cmdLEN.E2 + viewlinkheadlen,
  S1: viewlink_cmdLEN.S1 + viewlinkheadlen,
  S2: viewlink_cmdLEN.S2 + viewlinkheadlen,
  U: viewlink_cmdLEN.U + viewlinkheadlen,
  V: viewlink_cmdLEN.V + viewlinkheadlen,
  M_AHRS: viewlink_cmdLEN.M_AHRS + viewlinkheadlen,
  HEART_BEAT: viewlink_cmdLEN.HEART_BEAT + viewlinkheadlen,
  SHAKE_HAND: viewlink_cmdLEN.SHAKE_HAND + viewlinkheadlen
};

var mmcAddLen = 2; // (len+cs)
var mmcCmdLen = {
  // the position of checksum
  A1C1E1: viewlinkFrameLen.A1C1E1 + mmcAddLen,
  A1C1E1S1: viewlinkFrameLen.A1C1E1S1 + mmcAddLen,
  A2C2E2: viewlinkFrameLen.A2C2E2 + mmcAddLen,
  A2C2E2S2: viewlinkFrameLen.A2C2E2S2 + mmcAddLen,
  A1: viewlinkFrameLen.A1 + mmcAddLen,
  A2: viewlinkFrameLen.A2 + mmcAddLen,
  C1: viewlinkFrameLen.C1 + mmcAddLen,
  C2: viewlinkFrameLen.C2 + mmcAddLen,
  E1: viewlinkFrameLen.E1 + mmcAddLen,
  E2: viewlinkFrameLen.E2 + mmcAddLen,
  S1: viewlinkFrameLen.S1 + mmcAddLen,
  S2: viewlinkFrameLen.S2 + mmcAddLen,
  U: viewlinkFrameLen.U + mmcAddLen,
  V: viewlinkFrameLen.V + mmcAddLen,
  M_AHRS: viewlinkFrameLen.M_AHRS + mmcAddLen,
  HEART_BEAT: viewlinkFrameLen.HEART_BEAT + mmcAddLen,
  SHAKE_HAND: viewlinkFrameLen.SHAKE_HAND + mmcAddLen
};
var mmcHeadLen = 2;
var mmcFrameLen = {
  // the position of checksum
  A1C1E1: mmcCmdLen.A1C1E1 + mmcHeadLen,
  A1C1E1S1: mmcCmdLen.A1C1E1S1 + mmcHeadLen,
  A2C2E2: mmcCmdLen.A2C2E2 + mmcHeadLen,
  A2C2E2S2: mmcCmdLen.A2C2E2S2 + mmcHeadLen,
  A1: mmcCmdLen.A1 + mmcHeadLen,
  A2: mmcCmdLen.A2 + mmcHeadLen,
  C1: mmcCmdLen.C1 + mmcHeadLen,
  C2: mmcCmdLen.C2 + mmcHeadLen,
  E1: mmcCmdLen.E1 + mmcHeadLen,
  E2: mmcCmdLen.E2 + mmcHeadLen,
  S1: mmcCmdLen.S1 + mmcHeadLen,
  S2: mmcCmdLen.S2 + mmcHeadLen,
  U: mmcCmdLen.U + mmcHeadLen,
  V: mmcCmdLen.V + mmcHeadLen,
  M_AHRS: mmcCmdLen.M_AHRS + mmcHeadLen,
  HEART_BEAT: mmcCmdLen.HEART_BEAT + mmcHeadLen,
  SHAKE_HAND: mmcCmdLen.SHAKE_HAND + mmcHeadLen
};
var viewlinkheadlen = 3;
var viewlinkFrameLen = {
  // the position of checksum
  A1C1E1: viewlink_cmdLEN.A1C1E1 + viewlinkheadlen,
  A1C1E1S1: viewlink_cmdLEN.A1C1E1S1 + viewlinkheadlen,
  A2C2E2: viewlink_cmdLEN.A2C2E2 + viewlinkheadlen,
  A2C2E2S2: viewlink_cmdLEN.A2C2E2S2 + viewlinkheadlen,
  A1: viewlink_cmdLEN.A1 + viewlinkheadlen,
  A2: viewlink_cmdLEN.A2 + viewlinkheadlen,
  C1: viewlink_cmdLEN.C1 + viewlinkheadlen,
  C2: viewlink_cmdLEN.C2 + viewlinkheadlen,
  E1: viewlink_cmdLEN.E1 + viewlinkheadlen,
  E2: viewlink_cmdLEN.E2 + viewlinkheadlen,
  S1: viewlink_cmdLEN.S1 + viewlinkheadlen,
  S2: viewlink_cmdLEN.S2 + viewlinkheadlen,
  U: viewlink_cmdLEN.U + viewlinkheadlen,
  V: viewlink_cmdLEN.V + viewlinkheadlen,
  M_AHRS: viewlink_cmdLEN.M_AHRS + viewlinkheadlen,
  HEART_BEAT: viewlink_cmdLEN.HEART_BEAT + viewlinkheadlen,
  SHAKE_HAND: viewlink_cmdLEN.SHAKE_HAND + viewlinkheadlen
};
var viewlink_cmdID = {
  A1C1E1: 0x30,
  A1C1E1S1: 0x32,
  A2C2E2: 0x31,
  A2C2E2S2: 0x33,
  A1: 0x1a,
  A2: 0x2a,
  C1: 0x1c,
  C2: 0x2c,
  E1: 0x1e,
  E2: 0x2e,
  S1: 0x16,
  S2: 0x26,
  U: 0x01,
  V: 0x02,
  M_AHRS: 0xb1,
  HEART_BEAT: 0x10,
  SHAKE_HAND: 0x00,
  T1F1B1D1: 0x40,
  T2F2B2D2: 0x41,
  FW: 0xff
};

var mmc_cs_pos = {
  // the position of checksum
  A1C1E1: mmcFrameLen.A1C1E1 - 1,
  A1C1E1S1: mmcFrameLen.A1C1E1S1 - 1,
  A2C2E2: mmcFrameLen.A2C2E2 - 1,
  A2C2E2S2: mmcFrameLen.A2C2E2S2 - 1,
  A1: mmcFrameLen.A1 - 1,
  A2: mmcFrameLen.A2 - 1,
  C1: mmcFrameLen.C1 - 1,
  C2: mmcFrameLen.C2 - 1,
  E1: mmcFrameLen.E1 - 1,
  E2: mmcFrameLen.E2 - 1,
  S1: mmcFrameLen.S1 - 1,
  S2: mmcFrameLen.S2 - 1,
  U: mmcFrameLen.U - 1,
  V: mmcFrameLen.V - 1,
  M_AHRS: mmcFrameLen.M_AHRS - 1,
  HEART_BEAT: mmcFrameLen.HEART_BEAT - 1,
  SHAKE_HAND: mmcFrameLen.SHAKE_HAND - 1
};

var viewlink_cs_pos = {
  // the position of checksum
  A1C1E1: viewlinkFrameLen.A1C1E1 - 1,
  A1C1E1S1: viewlinkFrameLen.A1C1E1S1 - 1,
  A2C2E2: viewlinkFrameLen.A2C2E2 - 1,
  A2C2E2S2: viewlinkFrameLen.A2C2E2S2 - 1,
  A1: viewlinkFrameLen.A1 - 1,
  A2: viewlinkFrameLen.A2 - 1,
  C1: viewlinkFrameLen.C1 - 1,
  C2: viewlinkFrameLen.C2 - 1,
  E1: viewlinkFrameLen.E1 - 1,
  E2: viewlinkFrameLen.E2 - 1,
  S1: viewlinkFrameLen.S1 - 1,
  S2: viewlinkFrameLen.S2 - 1,
  U: viewlinkFrameLen.U - 1,
  V: viewlinkFrameLen.V - 1,
  M_AHRS: viewlinkFrameLen.M_AHRS - 1,
  HEART_BEAT: viewlinkFrameLen.HEART_BEAT - 1,
  SHAKE_HAND: viewlinkFrameLen.SHAKE_HAND - 1
};

function A1_to_array(A1_CMD) {
  var array = new Array();
  array[0] = A1_CMD.server_status;

  array[1] = A1_CMD.param1 >> 8;
  array[2] = A1_CMD.param1 & 0xff;

  array[3] = A1_CMD.param2 >> 8;
  array[4] = A1_CMD.param2 & 0xff;

  array[5] = A1_CMD.param3 >> 8;
  array[6] = A1_CMD.param3 & 0xff;

  array[7] = A1_CMD.param4 >> 8;
  array[8] = A1_CMD.param4 & 0xff;
  return array;
}
function viewlink_checksum(array) {
  var checksum = array[3];
  var i = 4;
  var len = (array[3] & 0x3f) - 2;
  while (len--) {
    checksum = checksum ^ array[i];
    i++;
  }
  return checksum;
}
function pack_A1(A1CMD) {
  const A1_data_array = A1_to_array(A1CMD); // 9
  const A1pack_array = [0x55, 0xaa, 0xdc, 0x00, 0x00];
  A1pack_array[3] = viewlink_cmdLEN.A1; // 12
  A1pack_array[4] = viewlink_cmdID.A1;

  A1_data_array.forEach(function (item) {
    // 将A1CMD的数据提取出，拼接在A1pack_array
    A1pack_array.push(item);
  });

  A1pack_array[viewlink_cs_pos.A1] = viewlink_checksum(A1pack_array);
  return A1pack_array;
}
// 数据分包发送函数
function package_send(buff, len) {
  var i = 0;
  var j = 0;
  var count = 0;
  // var alen = len;
  var hexbuf = [];
  var sendbuf = [];

  if (len % 48 == 0) {
    count = parseInt(len / 48, 10);
  } else {
    count = parseInt(len / 48, 10) + 1;
  }

  for (i = 0; i < count; i++) {
    if (count - i == 1) {
      for (j = 0; j < len - i * 48; j++) {
        sendbuf[j] = buff[i * 48 + j];
        hexbuf[j] = Number(buff[i * 48 + j]).toString(16);
      }
      return sendbuf;
    } else {
      for (j = 0; j < 48; j++) {
        sendbuf[j] = buff[i * 48 + j];
        hexbuf[j] = Number(buff[i * 48 + j]).toString(16);
      }
      return sendbuf;
    }
  }
}
export const MMC_Gimbal_Z60S = {
  change_pitch_angle(angle) {
    const A1_CMD_ANGLE = {
      server_status: 11,
      param1: 0,
      param2: 0,
      param3: 0,
      param4: 0
    };
    var buff = new Array(0xa5, 0x4f, 0x00);
    buff[2] = 17;
    A1_CMD_ANGLE.server_status = 11;
    var pitchangle = 0;
    if (angle >= 0) {
      pitchangle = angle;
      A1_CMD_ANGLE.param2 = (pitchangle * 65536) / 360;
    } else {
      pitchangle = angle;
      A1_CMD_ANGLE.param2 = (pitchangle * 65536) / 360 + 65536;
    }
    var buff_arr = pack_A1(A1_CMD_ANGLE);
    buff_arr.forEach(function (item) {
      buff.push(item);
    });
    buff[18] = cal_crc_table(buff);

    return package_send(buff, 19);
  },
  change_yaw_angle(num) {
    const angle = Number(num);
    const buff = new Array(0xa5, 0x4f, 0x00);
    buff[2] = mmcCmdLen.A1;
    A1_CMD_ANGLE.server_status = A1_SERVO_STATUS.turntoframeangle;
    let yawangle = 0;
    if (angle >= 0) {
      yawangle = angle;
      A1_CMD_ANGLE.param1 = (yawangle * 65536) / 360;
    } else {
      yawangle = angle;
      A1_CMD_ANGLE.param1 = (yawangle * 65536) / 360 + 65536;
    }
    const buff_arr = pack_A1(A1_CMD_ANGLE);
    buff_arr.forEach(function (item) {
      buff.push(item);
    });
    console.log(buff_arr, 'buff_arr');
    buff[mmc_cs_pos.A1] = cal_crc_table(buff);
    return package_send(buff, mmcFrameLen.A1);
  }
};
